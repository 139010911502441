import ArrowRightBlue from '@/assets/img/icons/icon_arrow_right_sky@2x.png';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ItemProps {
  itemData: DataItem[];
  arrow: boolean;
  blit: boolean;
  type?: string;
}

export interface DataItem {
  id: string | number;
  title: string;
  titleEn: string;
  img: ImgHTMLAttributes<any>['src'];
  desc: [{ id: string | number; dec: string; link?: string }];
  dec: string;
}

const ProcessContainer = styled.div`
  ol {
    display: flex;
    justify-content: ${(props) => props.justifyContent ?? 'space-between'};
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -16px;
    ${breakpoint(640)} {
      margin: 0;
    }

    &.arrow {
      dt {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -13px;
          transform: translate(50%, -50%);
          display: block;
          width: 44px;
          height: 15px;
          background: url(${ArrowRightBlue}) center no-repeat;
          background-size: cover;
        }
      }
    }

    & > li {
      position: relative;
      width: 25%;
      text-align: center;
      padding: 0 16px;

      &:last-child {
        dt {
          &::after {
            display: none;
          }
        }
      }

      ${breakpoint(`tablet`)} {
        & {
          width: 50%;
          margin-bottom: 48px;

          &:nth-child(2) {
            dt {
              &::after {
                display: none;
              }
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.type-2 {
    ol {
      padding: 0;
      margin: 0 -20px;

      > li {
        padding: 0 20px;
        width: 50%;
        text-align: left;
        margin-bottom: 32px;

        dl {
          display: flex;

          dt {
            width: 96px;
            padding: 0;
            ${breakpoint(640)} {
              width: 80px;
            }

            .img-wrap {
              width: 80px;
            }
          }

          dd {
            text-align: left;
            width: 100%;
            padding-left: 16px;

            ${breakpoint(640)} {
              padding-left: 12px;
            }

            h3 {
              text-align: left;
              margin-top: 0;

              ${Tit} {
                font-size: 32px;

                ${breakpoint(1160)} {
                  font-size: 2.76vw;
                }
                ${breakpoint(`mobile`)} {
                  font-size: 20px;
                }
              }
            }
            ul {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
`;
const ProcessDL = styled.dl`
  dt {
    position: relative;

    .img-wrap {
      max-width: 160px;
      width: 100%;
      display: inline-block;

      .img-box {
        width: 100%;
        padding-top: 100%;
      }
    }

    ${breakpoint(`tablet`)} {
      .img-wrap {
        max-width: 80px;
      }
    }
  }

  dd {
    h3 {
      margin-top: 16px;
      margin-bottom: 8px;
      line-height: 1.6;
      letter-spacing: -1.2px;
      text-align: center;
    }

    ul {
      & > li {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        & > * {
          display: inline-block;
        }

        span + span {
          display: block;
        }

        .link {
          line-height: 28px;
          padding: 0 10px;
          background: #1cabe2;
          color: #fff;
          margin-left: 8px;
        }
      }

      &.blit > li {
        padding-left: 10px;
        text-align: left;
        font-size: 14px;

        &::before {
          content: '•';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .dec {
      display: inline-block;
      color: #b7b8ba;
      font-size: 14px;
      text-align: left;
    }
  }
`;
/* 기존 ProcessList */
const ProcessList = styled<FC<ItemProps>>(
  ({ itemData, arrow, blit, type, ...props }) => (
    <ProcessContainer
      className={type || `ProcessContainer`}
      justifyContent={props.justifyContent}
    >
      <ol className={arrow ? `process-ol arrow` : `process-ol`}>
        {itemData.map((row) => (
          <li className="list-item" key={row.id}>
            <ProcessDL>
              <dt>
                <div className="img-wrap">
                  <div
                    className="img-box"
                    css={`
                      background-image: url(${row.img});
                    `}
                  />
                </div>
              </dt>
              <dd>
                {row.title ? (
                  <h3>
                    <Tit
                      size="s4"
                      color="sky"
                      dangerouslySetInnerHTML={{ __html: row.title }}
                    />
                  </h3>
                ) : (
                  ``
                )}
                {row.titleEn ? <Tit size="s4">{row.titleEn}</Tit> : ``}
                <ul className={blit ? `dec-ul blit` : `dec-ul`}>
                  {row.desc.map((row2) => (
                    <li key={row2.id}>
                      {type === `people` ? (
                        <p
                          dangerouslySetInnerHTML={{ __html: row2.dec }}
                          css={`
                            font-weight: bold;
                          `}
                        />
                      ) : (
                        <>
                          <p dangerouslySetInnerHTML={{ __html: row2.dec }} />
                          {row2.link && (
                            <Link to={row2.link} className="link">
                              문의
                            </Link>
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                {row.dec && <p className="dec">{row.dec}</p>}
              </dd>
            </ProcessDL>
          </li>
        ))}
      </ol>
    </ProcessContainer>
  ),
)``;
/* h3 태그 삭제 */
const ProcessListSeo = styled<FC<ItemProps>>(
  ({ itemData, arrow, blit, type, ...props }) => (
    <ProcessContainer
      className={type || `ProcessContainer`}
      justifyContent={props.justifyContent}
    >
      <ol className={arrow ? `process-ol arrow` : `process-ol`}>
        {itemData.map((row) => (
          <li className="list-item" key={row.id}>
            <ProcessDL>
              <dt>
                <div className="img-wrap">
                  <div
                    className="img-box"
                    css={`
                      background-image: url(${row.img});
                    `}
                  />
                </div>
              </dt>
              <dd>
                {row.title ? (
                  <Tit
                    size="s4"
                    color="sky"
                    dangerouslySetInnerHTML={{ __html: row.title }}
                    css={`
                      margin: 16px 0;
                    `}
                  />
                ) : (
                  ``
                )}
                {row.titleEn ? <Tit size="s4">{row.titleEn}</Tit> : ``}
                <ul className={blit ? `dec-ul blit` : `dec-ul`}>
                  {row.desc.map((row2) => (
                    <li key={row2.id}>
                      {type === `people` ? (
                        <p
                          dangerouslySetInnerHTML={{ __html: row2.dec }}
                          css={`
                            font-weight: bold;
                          `}
                        />
                      ) : (
                        <>
                          <p dangerouslySetInnerHTML={{ __html: row2.dec }} />
                          {row2.link && (
                            <Link to={row2.link} className="link">
                              문의
                            </Link>
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                {row.dec && <p className="dec">{row.dec}</p>}
              </dd>
            </ProcessDL>
          </li>
        ))}
      </ol>
    </ProcessContainer>
  ),
)``;
/* where-we-work ProcessList */
const ProcessListWhereWeWork = styled<FC<ItemProps>>(
  ({ itemData, arrow, blit, type, ...props }) => (
    <ProcessContainer
      className={type || `ProcessContainer`}
      justifyContent={props.justifyContent}
    >
      <ol className={arrow ? `process-ol arrow` : `process-ol`}>
        {itemData.map((row) => (
          <li className="list-item" key={row.id}>
            <ProcessDL>
              <dt>
                <div className="img-wrap">
                  <div
                    className="img-box"
                    css={`
                      background-image: url(${row.img});
                    `}
                  />
                </div>
              </dt>
              <dd>
                {row.title ? (
                  <h3
                    dangerouslySetInnerHTML={{ __html: row.title }}
                    css={`
                      font-size: 16px;
                      font-weight: normal;
                      ${breakpoint(640)} {
                        font-size: 14px;
                      }
                    `}
                  />
                ) : (
                  ``
                )}
                {row.desc.map((row2) => (
                  <p>
                    <Tit
                      size="s4"
                      color="sky"
                      dangerouslySetInnerHTML={{ __html: row2.dec }}
                    />
                  </p>
                ))}
              </dd>
            </ProcessDL>
          </li>
        ))}
      </ol>
    </ProcessContainer>
  ),
)``;

export { ProcessList, ProcessListSeo, ProcessListWhereWeWork };
